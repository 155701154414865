// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-resources-js": () => import("/opt/build/repo/src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-search-js": () => import("/opt/build/repo/src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-chapter-js": () => import("/opt/build/repo/src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

